import Swiper from 'swiper';
import 'swiper/css';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import './vendor/jquery.custom-select.js';
import { throttle } from 'lodash';

let price;
let swiper;
let swiper_4;
let swiper_5;

/**
 * Init variation buttons.
 */
function initVariarionAddButton() {
	let variation_id;
	if (
		$(
			'.select-dropdown--third .custom-select__option--value .custom-select__option-content',
		).length
	) {
		variation_id = $(
			'.select-dropdown--third .custom-select__option--value .custom-select__option-content',
		).data('variation-id');
	}

	$('.js-button-add-cart').attr('data-variation_id', variation_id);
	$('.js-button-add-cart').attr('data-product_id', variation_id);
	$('.js-button-add-cart').attr('value', variation_id);

	const quantity = $('.amount-value-js').text();
	const url = '?add-to-cart=' + variation_id;
	$('.js-button-add-cart').attr('data-quantity', quantity).attr('href', url);
	$('#modal-a-wrap .js-modal_price').text((price * amount).toFixed(2));
}

/**
 * Init all regular modals on page.
 *
 * @see initAjaxModal
 */
function initModal() {
	price = Number($('.js-modal_price').text());
	$('.toggle-slide-btn-js').on('click', function () {
		const content = $(this).siblings('.toggle-slide-content-js'),
			block = $(this).parent('.toggle-slide-block-js');
		block.toggleClass('open-slide');
		content.stop().slideToggle(400);
	});
	custom_select_items_init($('#modal-a-wrap .select-custom--special'));
	custom_count_items_init();
}

/**
 * Add a custom click event to plus and minus buttons.
 */
function custom_count_items_init() {
	if (typeof window.custom_count_items_init !== 'undefined') {
		// Do not add events more than once.
		return;
	}
	$('body').on('click', '.amount-btn-js', function () {
	    console.log("called combinemainjs");
		const input = $(this).siblings('.amount-input-js'),
			text = $(this).siblings('.amount-value-js'),
			min_btn = $(this).siblings('.amount-btn-minus-js'),
			max_btn = $(this).siblings('.amount-btn-plus-js'),
			min = Number(input.attr('min')),
			max = Number(input.attr('max')),
			value = Number(input.val());
		if ($(this).is('.amount-btn-plus-js')) {
			if (max > value) {
				input.val(value + 1);
				min_btn.removeClass('pointer-event-none opacity-5');
				if (max === Number(input.val())) {
					$(this).addClass('pointer-event-none opacity-5');
				}
			} else {
				$(this).addClass('pointer-event-none opacity-5');
			}
		}
		if ($(this).is('.amount-btn-minus-js')) {
			if (min < value) {
				input.val(value - 1);
				$('.amount-btn-plus-js').removeClass('pointer-event-none opacity-5');
				if (min === Number(input.val())) {
					$(this).addClass('pointer-event-none opacity-5');
				}
			} else {
				$(this).addClass('pointer-event-none opacity-5');
			}
		}
		text.text(input.val());

		if ($(this).parents('#modal-a-wrap').length) {
			amount = Number(input.val());
			initVariarionAddButton();
		}
		if ($('button.button[name="update_cart"]').length) {
			$('button.button[name="update_cart"]').removeAttr('disabled');
		}
	});
	window.custom_count_items_init = true;
}

/**
 * Initialise custom SELECTs.
 *
 * @param {jQuery[]} els Choice containers.
 */
function custom_select_items_init(els) {
	if (els.length === 0) {
		return;
	}
	els.each(function (i, el) {
		// Do not add events more than once.
		if ($(el).data('custom_init') === '1') {
			return;
		}
		let html = false,
			includeValue = false;
		if ($(el).is('.select-custom--special')) {
			html = true;
			includeValue = true;
		}
		$(el).customSelect({
			placeholder:
				'<span class="placeholder-hide-text">' +
				$(el).data('placeholder') +
				'</span>',
			transition: 400,
			includeValue,
			html,
			showCallback() {},
			hideCallback() {},
		});
		if ($(el).is('.select-custom--special')) {
			$(el).siblings('.custom-select').addClass('custom-select--dropup-static');
		}
		if ($(el).is('.filter-cats-js')) {
			const custom_item = $(el)
				.siblings('.custom-select')
				.find('.custom-select__toggle-custom');
			$(el).change(function () {
				if ($(this).val() !== 'all') {
					custom_item.removeClass('custom-select__toggle-hide');
				} else {
					custom_item.addClass('custom-select__toggle-hide');
				}
			});
		}
		if ($(this).parents('#modal-a-wrap').length) {
			if ($(this).find('option:selected').length) {
				price = $(this).find('option:selected').data('price');
				initVariarionAddButton();
			}
			$(el).change(function () {
				price = $(this).find('option:selected').data('price');
				initVariarionAddButton();
			});
		}
		$(el).data('custom_init', 1);
	});
}

/**
 * Init all AJAX modals on page.
 * On clicking, post a search and open the result in modal.
 */
function initAjaxModal() {
	$('.js-initAjaxModal').on('click', function (e) {
		e.preventDefault();

		const id = $(this).data('product-id');
		const response = $('.js-modal-content');
		response.addClass('g-loading');
		const modal = $(this).attr('href');
		$(modal).addClass('active');
		if ($(modal).find('video').length) {
			$(modal).find('video')[0].play();
		}
		$.ajax({
			type: 'POST',
			url: bg_params.ajax_url,
			data: {
				id_product: id,
				action: 'bg_searchProduct',
			},
			dataType: 'json',
			success(result) {
				if (result.success === true) {
					response.html(result.data.content);
				} else {
					response.empty();
				}
				// init Modal script
				initModal();

				response.removeClass('g-loading');
			},
		});
	});
}

/**
 * Return a date [N] days into the future.
 * @param {number} add_days Days to add on today's date.
 * @return {Date} New date.
 */
function getNecessaryDate(add_days) {
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + add_days);
	return tomorrow;
}

/**
 * Animate the container to replace the fadeOut
 * or fadeIn to replace the container.
 *
 * @param {HTMLElement} fade_out Block to fade out.
 * @param {HTMLElement} fade_in Block to fade in.
 * @param {number} duration Animation duration.
 * @param {HTMLElement} container Container to animate.
 * @param {Function} onComplete Code to run after.
 * @param {Function} onStart Code to run before.
 */
function tab_anim({
	fade_out,
	fade_in,
	duration,
	container,
	onComplete,
	onStart,
}) {
	const tl = gsap.timeline();
	let def_duration = 0.15;
	if (duration) {
		def_duration = duration;
	}
	if ($(fade_out).length) {
		if ($(container).length) {
			tl.to(container, {
				height: $(fade_out).height(),
				duration: def_duration,
				onStart,
			}).to(
				fade_out,
				{
					display: 'none',
					opacity: 0,
					duration: def_duration,
					onComplete,
				},
				'<',
			);
		} else {
			tl.to(fade_out, {
				display: 'none',
				opacity: 0,
				duration: def_duration,
				onComplete,
				onStart,
			});
		}
	}
	if ($(fade_in).length) {
		if ($(container).length) {
			tl.set(fade_in, { display: 'block', onStart })
				.to(container, { height: 'auto', duration: def_duration })
				.to(
					fade_in,
					{
						opacity: 1,
						duration: def_duration,
						onComplete,
					},
					'<',
				);
		} else {
			tl.to(fade_in, {
				display: 'block',
				opacity: 1,
				duration: def_duration,
				onComplete,
				onStart,
			});
		}
	}
}

/**
 * Return a function that runs a function once
 * with a 300ms delay and stops.
 * @param {Function} func Function to run.
 * @return {Function} New function.
 */
function debounce(func) {
	let timer;
	return function (event) {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(func, 300, event);
	};
}

/**
 * Copy the HTML contents of the specified element to clipboard.
 * @param {HTMLElement} nodeEl Element to copy from.
 */
function copyText(nodeEl) {
	if (
		typeof navigator.clipboard !== 'undefined' &&
		typeof navigator.clipboard.write !== 'undefined'
	) {
		navigator.clipboard.write(nodeEl.innerHTML);
	} else {
		// Identify hidden field
		const hiddenPut = document.getElementById('copyMaker');
		// Passes nodeEl contents to hidden field's value
		hiddenPut.value = nodeEl.innerHTML;
		// Selects hidden field's contents
		hiddenPut.select();
		// Copy
		document.execCommand('copy');
	}
}

/**
 * WC automatically appends the error message to the form.
 * Move the WC error message under woocommerce-input-wrapper
 * and wrap it into woocommerce-error class.
 */
function error_message_location() {
	if ($('.woocommerce-error').find('li').length) {
		$('.woocommerce-error')
			.find('li')
			.each(function () {
				const input = $(this).data('id'),
					wrapper = $('#' + input).parent('.woocommerce-input-wrapper'),
					text =
						'<div class="woocommerce"><div class="woocommerce-error">' +
						$(this).html() +
						'</div></div>';
				$(text).appendTo(wrapper);
			});
	}
}

/**
 * Make the specified slide active and switch to it.
 * @param {HTMLElement} item Slide to switch to.
 */
function active_slide(item) {
	thumbs.find('.tabs-b__thumbs-slide').removeClass('active');
	$(item).addClass('active');
	const index = $(item).index();
	swiper_5.slideTo(index);
	swiper_4.slideTo(index);
}

/**
 * Clone all slides in .scroll-slider
 */
function clone_slides() {
	$('.scroll-slider__slide-js').each(function (i, el) {
		$(this)
			.clone()
			.addClass('clone-slide')
			.appendTo($(this).parents('.scroll-slider-js'));
	});
}

/**
 * Reset all category sliders.
 */
function update_swiper_filter() {
	if (typeof window.swiper_cats !== 'undefined') {
		for (let i = 0; i < swiper_cats.length; i++) {
			swiper_cats[i].updateSlides();
			swiper_cats[i].slideTo(0);
		}
	}
	ScrollTrigger.refresh();
}

/**
 * Stop animations for blocks out of view.
 */
function hero_block_pos() {
	$('.hero-item-1-js').each(function (i, el) {
		if (
			$(el).offset().top >= window.innerHeight ||
			$(window).scrollTop() >= window.innerHeight
		) {
			$(el).removeClass('hero-item-1-js').addClass('block-anim-js');
			$(el)
				.parents('.container-hero-anim-js')
				.removeClass('container-hero-anim-js')
				.addClass('container-anim-js');
		}
	});
}

/**
 * Refresh cart on changing any amounts.
 */
function emulate_sending() {
	if (typeof window.emulated_sending !== 'undefined') {
		return;
	}
	$('body').on('click', '.woocommerce-cart-form .amount-btn-js', function () {
		$('.button[name="update_cart"]').trigger('click');
	});
	window.emulated_sending = true;
}

/**
 * @param {gsap.timeline} hero_tl Timeline to modify.
 * @param {HTMLElement} block Block to animate.
 * @param {number} delay Animation delay.
 */
function hero_animation(hero_tl, block, delay) {
	if ($(block).length === 0) {
		return;
	}
	const stagger_step = 0.1;
	hero_tl.from(
		block,
		{
			autoAlpha: 0,
			stagger: stagger_step,
		},
		delay,
	);
}

/**
 * Main code block.
 */
if (typeof $ !== 'undefined') {
	if ($('#header-anim-diable-js').length) {
		$('.container-hero-anim-js').removeClass('container-hero-anim-js');
		$('.container-anim-js').removeClass('container-anim-js');
	}
	$('#header-a__burger').on('click', function () {
		$('#header-a').toggleClass('menu-active');
	});
	$('.header-a__list-link-item-child-menu.header-a__dropdown-content')
		.parents('.header-a__list-link-item')
		.addClass('header-a__dropdown')
		.find('.header-a__list-link')
		.addClass('header-a__dropdown-btn');
	if ($('.def-input-js + .def-placeholder-js').length) {
		$('.def-input-js').on('focusout', function () {
			const val = $(this).val(),
				placehold = $(this).siblings('.def-placeholder-js');
			if (val !== '') {
				placehold.addClass('opacity-0-main');
			} else {
				placehold.removeClass('opacity-0-main');
			}
		});
	}
	if (window.innerWidth < 1200) {
		$('.header-a__dropdown-btn').on('click', function () {
			const content = $(this).siblings('.header-a__dropdown-content'),
				wrap = $(this).parent('.header-a__dropdown');
			$('.header-a__dropdown--open .header-a__dropdown-content')
				.not(content)
				.stop()
				.slideUp(400);
			$('.header-a__dropdown.header-a__dropdown--open')
				.not(wrap)
				.removeClass('header-a__dropdown--open');
			if (wrap.is('.header-a__dropdown--open')) {
				wrap.removeClass('header-a__dropdown--open');
				content.stop().slideUp(400);
			} else {
				wrap.addClass('header-a__dropdown--open');
				content.stop().slideDown(400);
			}
			return false;
		});
	}

	window.addEventListener('load', function () {
		if ($('#grid-b').length) {
			if (window.innerWidth > 991) {
				$('#grid-b').isotope({
					percentPosition: true,
					itemSelector: '.grid-b-item',
				});
			} else {
				const grid = $('#grid-b');
				grid.parent('.grid-b-wrap').addClass('swiper grid-b-swiper');
				grid.addClass('swiper-wrapper');
				grid.find('.grid-b-item').addClass('swiper-slide');
				swiper = new Swiper('.grid-b-swiper', {
					speed: 900,
					spaceBetween: 0,
					slidesPerView: 'auto',
					breakpoints: {
						576: {
							spaceBetween: 16,
						},
					},
				});
			}

			/*
			 * $gallery.imagesLoaded().progress(function() {
			 *     $gallery.isotope('layout');
			 * });
			 */
		}
		//  -----------------------------------------
		$('.content-th-js').each(function (index, item) {
			const txt_block = $(item).find('.content-th__txt-wrap-js');
			const content_txt = $(item).find('.content-th__txt-js');
			let set_height = $(item).data('txt-block-height');
			if (window.innerWidth < 1200 && $(item).data('xl-block-height')) {
				set_height = $(item).data('xl-block-height');
			}
			gsap.set(txt_block, { height: set_height });

			const btn = $(item).find('.content-th__btn-js');
			btn.on('click', function () {
				if (window.innerWidth < 1200 && $(item).data('xl-block-height')) {
					set_height = $(item).data('xl-block-height');
				}
				if ($(item).is('.open-content')) {
					$(item).removeClass('open-content');
					gsap.to(txt_block, {
						height: set_height,
						onComplete() {
							ScrollTrigger.refresh(true);
						},
					});
				} else {
					$(item).addClass('open-content');
					gsap.to(txt_block, {
						height: content_txt.height(),
						onComplete() {
							ScrollTrigger.refresh(true);
						},
					});
				}
				return false;
			});
		});
		//  -----------------------------------------
		if ($('.split-text-by-line-js').length) {
			const txt = new SplitText(
				'h1.split-text-by-line-js,.split-text-by-line-js p',
				{
					type: 'lines',
					linesClass: 'anim-lines',
				},
			);
		}
		hero_block_pos();
		window.addEventListener(
			'resize',
			debounce(function (e) {
				if (window.innerWidth < 1200) {
					$('#shopping-cart-a__block-btn-wrap').appendTo(
						'#shopping-cart-a__container-v2',
					);
				}
				if (window.innerWidth > 1199) {
					$('#shopping-cart-a__block-btn-wrap').appendTo(
						'#shopping-cart-a__block',
					);
				}
			}),
		);
		if (
			$('#shopping-cart-a__block-btn-wrap').length &&
			window.innerWidth < 1200
		) {
			$('#shopping-cart-a__block-btn-wrap').addClass('scrollTrigger-enable');
			const hero_btn_tl = gsap.timeline({
				scrollTrigger: {
					trigger: '#shopping-cart-a',
					endTrigger: '#shopping-cart-a__container-v2',
					start: 'top top',
					end: 'bottom bottom',
					onLeave() {
						$('#shopping-cart-a__block-btn-wrap').addClass('hide-fixed-styles');
					},
					onEnterBack() {
						$('#shopping-cart-a__block-btn-wrap').removeClass(
							'hide-fixed-styles',
						);
					},
				},
			});
		}
		const duration_opacity = 0.5,
			duration_transform = 0.7,
			stagger_step = 0.1,
			stagger_lines = 0.05;
		// ------------------------------
		const delay_0 = 0,
			delay_1 = 0.1,
			delay_2 = 0.2,
			delay_3 = 0.3;
		let delay_4 = 0.4,
			delay_5 = 0.4,
			delay_6 = 0.9,
			delay_7 = 0.9;
		const delay_7_2 = 0.9;
		let delay_8 = 0.9,
			delay_9 = 0.9;
		if ($('#page-404-a').length) {
			delay_4 = 0.1;
			delay_5 = 0.1;
			delay_6 = 0.2;
			delay_7 = 0.2;
			delay_8 = 0.2;
			delay_9 = 0.3;
		}
		if (
			$('#shopping-cart-a').length ||
			$('.woocommerce-cart').length ||
			$('.woocommerce-checkout').length ||
			$('.woocommerce-finish').length
		) {
			delay_8 = 0.15;
			delay_9 = 0.1;
		}
		// ------------------------------
		if (
			$('.woocommerce-cart').length ||
			$('.woocommerce-checkout').length ||
			$('.woocommerce-finish').length
		) {
			$(
				'.header-a .container-hero-anim-js .header-item-3-js,.header-a .container-hero-anim-js .header-item-2-js,.header-a .container-hero-anim-js .header-item-4-js',
			).removeClass('header-item-3-js header-item-2-js header-item-4-js');
		}
		// ------------------------------
		const hero_tl = gsap.timeline({
			paused: true,
			defaults: {
				ease: 'power2.inOut',
				duration: 0.2,
			},
		});

		hero_animation(hero_tl, '.header-item-1-js', delay_0);
		hero_animation(hero_tl, '.header-item-2-js', delay_1);
		hero_animation(hero_tl, '.header-item-3-js', delay_2);
		hero_animation(hero_tl, '.header-item-4-js', delay_3);
		hero_animation(hero_tl, '.header-item-5-js', delay_7_2);

		if ($('.hero-title-js .anim-lines').length) {
			hero_tl.from(
				'.hero-title-js .anim-lines',
				{
					autoAlpha: 0,
					duration: duration_opacity,
					stagger: stagger_lines,
				},
				delay_4,
			);
			hero_tl.from(
				'.hero-title-js .anim-lines',
				{
					y: 30,
					duration: duration_transform,
					stagger: stagger_lines,
				},
				delay_5,
			);
		}
		if ($('.hero-txt-js .anim-lines').length) {
			hero_tl.from(
				'.hero-txt-js .anim-lines',
				{
					autoAlpha: 0,
					duration: duration_opacity,
					stagger: stagger_lines,
				},
				delay_6,
			);
			hero_tl.from(
				'.hero-txt-js .anim-lines',
				{
					y: 30,
					duration: duration_transform,
					stagger: stagger_lines,
				},
				delay_7,
			);
		}
		if ($('.hero-item-1-js').length) {
			hero_tl.from(
				'.hero-item-1-js',
				{
					autoAlpha: 0,
					duration: duration_opacity,
					stagger: stagger_step,
				},
				delay_8,
			);
			hero_tl.from(
				'.hero-item-1-js',
				{
					y: 30,
					duration: duration_transform,
					stagger: stagger_step,
				},
				delay_9,
			);
		}
		gsap.set('.container-hero-anim-js', {
			autoAlpha: 1,
		});
		hero_tl.play();
		//    -----------------------------------------
		if (
			$('.parallax-percent-js.locations-b__block').length &&
			window.innerWidth < 992
		) {
			$('.parallax-percent-js.locations-b__block').removeClass(
				'primary-parallax-js',
			);
		}
		//    -----------------------------------------
		gsap.set('.container-anim-js', {
			autoAlpha: 1,
		});

		if ($('.primary-parallax-js').length) {
			$('.primary-parallax-js').each(function (i, el) {
				let y_value = 150,
					start_pos = 'top bottom',
					end_pos = 'bottom top+=100px',
					trig = el,
					scrub_val = 0.7;
				if ($(el).parents('.parallax-trigger-js').length) {
					trig = $(el).parents('.parallax-trigger-js');
				}
				if ($(el).is('.parallax-hero-js')) {
					start_pos = 'top top';
					end_pos = 'bottom top';
				}
				if ($(el).data('scrub')) {
					scrub_val = $(el).data('scrub');
				}
				if ($(el).data('parallax-value')) {
					y_value = $(el).data('parallax-value');
				}
				if ($(el).data('parallax-value-lg') && window.innerWidth > 991) {
					y_value = $(el).data('parallax-value-lg');
				}
				const parallax_tl = gsap.timeline({
					scrollTrigger: {
						trigger: trig,
						start: start_pos,
						end: end_pos,
						// markers: true,
						scrub: scrub_val,
					},
				});
				if ($(el).is('.parallax-percent-js')) {
					parallax_tl.to(el, {
						yPercent: y_value,
						duration: 1,
						ease: 'none',
					});
				}
				if ($(el).is('.primary-parallax-hor-js')) {
					parallax_tl.to(el, {
						x: y_value,
						duration: 1,
						ease: 'none',
					});
				}
				if ($(el).is('.primary-parallax-scale-js')) {
					parallax_tl.to(el, {
						scale: 1.2,
						duration: 1,
						ease: 'none',
					});
				}
				if ($(el).is('.parallax-def-js')) {
					if ($(el).is('.parallax-def-reverse-js')) {
						parallax_tl.to(el, {
							y: -y_value,
							duration: 1,
							ease: 'none',
						});
					} else {
						parallax_tl.to(el, {
							y: y_value,
							duration: 1,
							ease: 'none',
						});
					}
				}
			});
		}
		if ($('.video-js').length) {
			$('.video-js').each(function (i, el) {
				const video = el,
					container = $(el).parents('.container-video-js');
				video.pause();
				const video_tl = gsap.timeline({
					scrollTrigger: {
						trigger: container,
						start: 'top bottom',
						end: 'bottom top',
						onEnter() {
							if (video.readyState >= 2) {
								video.play();
							}
						},
						onLeave() {
							if (video.playing) {
								video.pause();
							}
						},
						onEnterBack() {
							if (video.readyState >= 2) {
								video.play();
							}
						},
						onLeaveBack() {
							if (video.playing) {
								video.pause();
							}
						},
					},
				});
			});
		}
		//    -----------------------------------------
		if ($('#inline-datepicker').length) {
			const date = new Date();
			const datepicker = new AirDatepicker('#inline-datepicker', {
				inline: true,
				minDate: date,
				maxDate: getNecessaryDate(5),
			});
		}
		//    -----------------------------------------
		if ($('#date-1').length) {
			const date = new Date();
			new AirDatepicker('#date-1', {
				minDate: date,
				maxDate: getNecessaryDate(5),
				onSelect() {
					$(
						'#input-style__placeholder-data .input-style__placeholder-txt',
					).addClass('opacity-0');
				},
			});
		}
		if ($('#time-1').length) {
			new AirDatepicker('#time-1', {
				onlyTimepicker: true,
				timepicker: true,
				minHours: 9,
				maxHours: 18,
				minutesStep: 5,
				onSelect({ date, datepicker }) {
					$(
						'#input-style__placeholder-data-2 .input-style__placeholder-txt',
					).addClass('opacity-0');
				},
			});
		}
		if ($('.popular-swiper').length) {
			swiper = new Swiper('.popular-swiper', {
				slidesPerView: 'auto',
				speed: 900,
				spaceBetween: 24,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		}
		window.swiper_cats = null;
		if ($('.cat-swiper').length && window.innerWidth < 1200) {
			window.swiper_cats = new Swiper('.cat-swiper', {
				slidesPerView: 'auto',
				speed: 900,
				spaceBetween: 24,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		}
		if ($('.acc-item__title-js').length) {
			$('.acc-item__title-js').on('click', function () {
				const content = $(this).siblings('.acc-item__content-js'),
					active_content = $('.acc-item-js.active  .acc-item__content-js'),
					active_block = $(this)
						.parents('.acc-items')
						.find('.acc-item-js.active'),
					block_ = $(this).parents('.acc-item-js');
				if (block_.is('.active')) {
					block_.removeClass('active');
					content.stop().slideUp(400, function () {
						ScrollTrigger.refresh(true);
					});
				} else {
					if (active_block.length) {
						active_block.removeClass('active');
					}
					if (active_content.length) {
						active_content.stop().slideUp(400, function () {
							ScrollTrigger.refresh(true);
						});
					}
					block_.addClass('active');
					content.stop().slideDown(400, function () {
						ScrollTrigger.refresh(true);
					});
				}
			});
			if ($('.acc-item-wrap').length) {
				$('.acc-item-wrap:nth-child(3) .acc-item-js').addClass('active');
				$('.acc-item-js.active .acc-item__content-js').show(0);
			} else {
				$('.acc-item-js:first-child').addClass('active');
				$('.acc-item-js.active .acc-item__content-js').show(0);
			}
		}
		if ($('.link-copy-js').length) {
			$('.link-copy-js').on('click', function () {
				const text = $(this).siblings('.text-copy-js'),
					tooltip = $(this).find('.tooltip-info');
				copyText(text[0]);
				tooltip.addClass('copies');
				clearTimeout();
				setTimeout(function () {
					tooltip.removeClass('copies');
				}, 500);
				return false;
			});
		}

		emulate_sending();

		$(document).ajaxComplete(function () {
			/**
			 * If modal is present on the page, enable opening and closing the modal.
			 */
			if ($('.modal-a-wrap-js').length) {
				$('.modal-a__close-js').on('click', function (e) {
					if ($(this).parents('.modal-a-wrap-js').find('video').length) {
						$(this).parents('.modal-a-wrap-js').find('video')[0].pause();
					}
					$(this).parents('.modal-a-wrap-js').removeClass('active');
					return false;
				});
				$('.call-modal-js').on('click', function () {
					const modal = $(this).attr('href');
					$(modal).addClass('active');
					return false;
				});
			}
			error_message_location();
			emulate_sending();
			custom_count_items_init();
		});

		if ($('.input-style + .input-style__placeholder').length) {
			$('.input-style').on('focusout', function () {
				const val = $(this).val(),
					placehold = $(this).siblings('.input-style__placeholder');
				if (val !== '') {
					placehold.addClass('opacity-0');
				} else {
					placehold.removeClass('opacity-0');
				}
			});
		}
		//    -----------------------------------------
		let $grid = null;
		if ($('.filter-cats-js').length) {
			const opt = {
				itemSelector: '.def-items-a-js .def-items-a__slide-js',
			};
			if (window.innerWidth > 1199) {
				$grid = $('.def-items-a-js .def-items-a__row').isotope(opt);
				$grid.on('arrangeComplete', function () {
					ScrollTrigger.refresh();
				});
			}
			const all_items = $('.def-items-a__slide-js');
			// tab_anim
			$('.filter-cats-js').change(function () {
				const cat_ = $(this).val();
				if ($('.nocontents').length > 0) {
					$('.nocontents').destroy();
				}
				if (cat_ === 'all') {
					if (window.innerWidth > 1199) {
						$grid.isotope({ filter: '*' });
					}
					if (window.innerWidth < 1200) {
						all_items.stop().fadeIn(400, function () {
							update_swiper_filter();
						});
					}
				} else {
					if (window.innerWidth > 1199) {
						$grid.isotope({ filter: cat_ });
					}
					if (window.innerWidth < 1200) {
						all_items
							.not($(cat_))
							.stop()
							.fadeOut(400, function () {
								update_swiper_filter();
							});
						$(cat_)
							.stop()
							.fadeIn(400, function () {
								update_swiper_filter();
							});
					}
				}
				if ($(cat_).not(':visible').length === 0) {
					$('.def-items-a:not(.hidden) > .container-v2 > .swiper').append(
						"<div class='nocontents text-center'><h4>No products</h4></div>",
					);
				}
			});
		}
	/*	if ($('.filter-a__mini-btn-js').length) {
			let arr = [];
			const all_section = $('.def-items-a-js');
			const all_btns = $('.filter-a__mini-btn-js');
			$('.filter-a__mini-btn-js').on('click', function () {
				const cat = $(this).attr('href');
				arr = [];
				if (cat === 'all') {
					all_btns.removeClass('active');
					$(this).addClass('active');
					all_section.removeClass('h-0 d-flex');
					all_section.stop().slideDown(500, function () {
						ScrollTrigger.refresh();
					});
				} else {
					$('.active.filter-a__mini-btn-js[href="all"]').removeClass('active');
					$(this).toggleClass('active');
					const actives = $('.filter-a__mini-btn-js.active');
					if (actives.length) {
						actives.each(function (index, item) {
							const newcat = $(item).attr('href');
							arr.push(newcat);
						});
						const active_items = $(arr.toString());
						all_section
							.not('.h-0.d-flex')
							.not($(active_items))
							.stop()
							.slideUp(500, function () {
								all_section.not($(active_items)).addClass('h-0 d-flex');
								ScrollTrigger.refresh();
							});
						active_items.removeClass('h-0 d-flex');
						active_items.stop().slideDown(500, function () {
							ScrollTrigger.refresh();
						});
					} else {
						all_section.removeClass('h-0 d-flex');
						all_section.stop().slideDown(500, function () {
							ScrollTrigger.refresh();
						});
						$('.filter-a__mini-btn-js[href="all"]').addClass('active');
					}
				}
				if ($this.hasClass('active')) {
					window.setTimeout(() => {
						$('html, body').animate(
							{
								scrollTop: $(cat).offset().top - 75,
							},
							2000,
						);
					}, 600);
				}
				return false;
			});
		} */
		//    -----------------------------------------
		if ($('.locations-2-a__block-js').length) {
			if (window.innerWidth < 992) {
				$('.locations-2-a__block-js').each(function (index, item) {
					const content = $(item).data('content'),
						wrap = $(item).parents('.locations-2-a__block-mob-wrap-js');
					$(content).appendTo(wrap);
				});
			}
			$('.locations-2-a__block-js').on('click', function () {
				const content_active = $(this).data('content'),
					all_content = $('.locations-a__map-wrap-2-js').not($(content_active)),
					current_item = $(this),
					all_items = $('.locations-2-a__block-js').not($(this));
				if (window.innerWidth > 991) {
					tab_anim({
						fade_out: all_content,
						fade_in: content_active,
						onStart() {
							all_items.removeClass('active');
							current_item.addClass('active');
						},
					});
				}
				if (window.innerWidth < 992) {
					all_content.stop().slideUp();
					$(content_active)
						.stop()
						.slideDown(400, function () {
							ScrollTrigger.refresh();
						});
					all_items.removeClass('active');
					current_item.addClass('active');
				}
			});
		}
		//    -----------------------------------------
		if ($('.scroll-slider__slide-js').length) {
			clone_slides();
			$('.scroll-slider-js').each(function (i, el) {
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: el,
						start: 'top bottom',
						end: 'bottom top',
						toggleClass: 'active',
						// markers: true,
					},
				});
			});
			const parts = document.querySelectorAll('.scroll-slider__slide-js');
			parts.forEach(function (currentValue, index, array) {
				const part = currentValue;
				const root = currentValue.closest('.scroll-slider-wrap-js');
				const scrollContainer = currentValue.closest('.scroll-slider-js');
				const partWidth = part.clientWidth;
				scrollContainer.style.width = `${partWidth}px`;
				scrollContainer.style.animationName = 'scroll';
				const partClons = [];
				const updatePartCount = throttle(function () {
					const rootWidth = root.clientWidth;
					const cloneCount = Math.floor(rootWidth / partWidth);
					if (cloneCount > partClons.length) {
						const addCount = cloneCount - partClons.length;
						[...Array(addCount)].forEach(() => {
							const newNode = part.cloneNode(true);
							partClons.push(newNode);
							scrollContainer.appendChild(newNode);
						});
					}
					if (cloneCount < partClons.length) {
						const removeCount = partClons.length - cloneCount;
						[...Array(removeCount)].forEach(() => {
							const oldNode = partClons.pop();
							scrollContainer.removeChild(oldNode);
						});
					}
				}, 200);
				updatePartCount();
				window.addEventListener('resize', updatePartCount);
			});
		}
		//    -----------------------------------------
		if ($('#hero-b__col-1-circle-txt').length) {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: '#hero-b__col-1-circle-txt',
					start: 'top bottom',
					end: 'bottom top',
					toggleClass: {
						targets: '#hero-b__col-1-circle-txt',
						className: 'active',
					},
				},
			});
		}
		//    -----------------------------------------
		if ($('.swiper-hero-js').length) {
			$('.swiper-hero-js').each(function (index, item) {
				let direction = 'horizontal';
				const delay = 3000 + 300 * index;
				if ($(item).is('.swiper-hero--hor-js')) {
					direction = 'vertical';
				}
				swiper = new Swiper(item, {
					direction,
					speed: 900,
					parallax: true,
					loop: true,
					autoplay: {
						delay,
						disableOnInteraction: false,
					},
				});
				if (
					typeof swiper !== 'undefined' &&
					typeof swiper.autoplay !== 'undefined'
				) {
					swiper.autoplay.stop();
				}
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: item,
						start: 'top bottom',
						end: 'bottom top',
						onEnter() {
							if (
								typeof swiper !== 'undefined' &&
								typeof swiper.autoplay !== 'undefined'
							) {
								swiper.autoplay.start();
							}
						},
						onLeave() {
							if (
								typeof swiper !== 'undefined' &&
								typeof swiper.autoplay !== 'undefined'
							) {
								swiper.autoplay.stop();
							}
						},
						onEnterBack() {
							if (
								typeof swiper !== 'undefined' &&
								typeof swiper.autoplay !== 'undefined'
							) {
								swiper.autoplay.start();
							}
						},
						onLeaveBack() {
							if (
								typeof swiper !== 'undefined' &&
								typeof swiper.autoplay !== 'undefined'
							) {
								swiper.autoplay.stop();
							}
						},
					},
				});
			});
		}
		//    -----------------------------------------
		if ($('.s-slider-js').length) {
			$('.s-slider-js').each(function (i, el) {
				const thumbs = $(this).find('.s-slider__thumbs-swiper-js'),
					cards = $(this).find('.s-slider__swiper-js');
				swiper_4 = new Swiper(cards[0], {
					speed: 700,
					effect: 'fade',
					parallax: true,
					fadeEffect: {
						crossFade: true,
					},
					allowTouchMove: false,
				});
				swiper_4.on('slideChangeTransitionStart', function () {
					if (swiper_4.previousIndex > swiper_4.activeIndex) {
						$(swiper_4.el).addClass('prev-dir');
					} else {
						$(swiper_4.el).removeClass('prev-dir');
					}
				});
				swiper_5 = new Swiper(thumbs[0], {
					speed: 700,
					spaceBetween: 16,
					slidesPerView: 'auto',
					threshold: 10,
					breakpoints: {
						992: {
							spaceBetween: 4,
						},
					},
				});
				thumbs.find('.tabs-b__thumbs-slide').on('click', function () {
					active_slide(this);
				});
			});
		}
		//    -----------------------------------------
		if ($('.swiper-group-js').length) {
			if (
				window.innerWidth < 992 &&
				window.innerWidth > 575 &&
				$('.testimonials-b__swiper').length
			) {
				let slide_index = 0;
				$('.testimonials-b__swiper').addClass('for-2-items-in-slide');
				$('.testimonial-b-item-wrap:nth-child(3)').each(function (index, item) {
					$(item).appendTo('.testimonials-b__swiper-wrapper');
					slide_index++;
					if (slide_index > 2) {
						slide_index = 1;
					}
					if (slide_index === 1) {
						$(item).wrap(
							'<div class="swiper-slide testimonials-b__swiper-slide"></div>',
						);
					} else {
						$(item).appendTo($('.testimonials-b__swiper-slide').last());
					}
				});
			}
			if (window.innerWidth < 576 && $('.testimonials-b__swiper').length) {
				$('.testimonials-b__swiper').addClass('for-1-item-in-slide');
				$(
					'.testimonial-b-item-wrap:nth-child(3),.testimonial-b-item-wrap:nth-child(2)',
				).each(function (index, item) {
					$(item).appendTo('.testimonials-b__swiper-wrapper');
					$(item).wrap(
						'<div class="swiper-slide testimonials-b__swiper-slide"></div>',
					);
				});
			}
			swiper = new Swiper('.swiper-group-js', {
				speed: 700,
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: true,
				},
				touchAngle: 30,
				// parallax: true,
				grabCursor: true,
				spaceBetween: 24,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
				pagination: {
					el: '.swiper-pagination',
					// clickable: true,
				},
			});
			swiper.on('slideChangeTransitionStart', function () {
				if (swiper.swipeDirection === 'prev') {
					$(swiper.el).addClass('prev-dir');
				}
				if (swiper.swipeDirection === 'next') {
					$(swiper.el).removeClass('prev-dir');
				}
			});
			if (
				typeof swiper !== 'undefined' &&
				typeof swiper.autoplay !== 'undefined'
			) {
				swiper.autoplay.stop();
			}
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.swiper-group-js',
					start: 'top bottom-=100px',
					end: 'bottom top',
					// markers: true,
					onEnter() {
						if (
							typeof swiper !== 'undefined' &&
							typeof swiper.autoplay !== 'undefined'
						) {
							swiper.autoplay.start();
						}
					},
					onLeave() {
						if (
							typeof swiper !== 'undefined' &&
							typeof swiper.autoplay !== 'undefined'
						) {
							swiper.autoplay.stop();
						}
					},
					onEnterBack() {
						if (
							typeof swiper !== 'undefined' &&
							typeof swiper.autoplay !== 'undefined'
						) {
							swiper.autoplay.start();
						}
					},
					onLeaveBack() {
						if (
							typeof swiper !== 'undefined' &&
							typeof swiper.autoplay !== 'undefined'
						) {
							swiper.autoplay.stop();
						}
					},
				},
			});
		}
		//  -----------------------------------------
		if ($('#pin-contacts-a').length) {
			const tl_contacts = gsap.timeline({
				scrollTrigger: {
					trigger: '.footer-a__row-2 .footer-a__col:last-child',
					start: 'top bottom',
					end: 'bottom top',
					// markers: true,
					onEnter() {
						gsap.to('#pin-contacts-a', { autoAlpha: 0 });
					},
					onLeave() {
						gsap.to('#pin-contacts-a', { autoAlpha: 1 });
					},
					onEnterBack() {
						gsap.to('#pin-contacts-a', { autoAlpha: 0 });
					},
					onLeaveBack() {
						gsap.to('#pin-contacts-a', { autoAlpha: 1 });
					},
				},
			});
		}
		//  -----------------------------------------
		const ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf('safari') !== -1) {
			if (ua.indexOf('chrome') <= -1) {
				$('html').addClass('safari-browser');
			}
		}
		//  -----------------------------------------
	});

	$(function () {
		initAjaxModal();
	});

	custom_count_items_init();
	custom_select_items_init(
		$('.select-custom').not('#modal-a-wrap .select-custom'),
	);
}
